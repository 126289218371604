<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="py-2">
          <v-col cols="12">
            <v-select :items="services" filled label="Послуга"
                      hide-details
                      v-model="service_id"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12">
            <v-select :items="groups" filled label="Група тарифу"
                      hide-details
                      v-model="tariff_group_type_id"
                      color="grey"
            />
          </v-col>
          <v-col cols="12">
            <AC_Regular :value="regulator_parameters_type_id"
                        @autocompleteChange="regularChange"
                        :service_id="service_id"
                        :is_legacy="is_legacy"
            />
          </v-col>
          <v-col cols="12">
            <AC_RegularSocial :value="social_regulator_parameters_type_id"
                        @autocompleteChange="socialRegularChange"
                        :service_id="service_id"
                        :is_legacy="is_legacy"
            />
          </v-col>
          <v-col cols="6">
            <date-component v-model="date_start" req label="Початок"/>
          </v-col>
          <v-col cols="6">
            <date-component v-model="date_end" label="Закінчення"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="updateService" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {CREATE_REGULAR_BY_ORGANIZATION, UPDATE_REGULAR_BY_ORGANIZATION, DELETE_REGULAR_BY_ORGANIZATION} from "@/store/actions/regular_by_organization";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'tariff_by_organization_modal_delete'

export default {
  name: "HWP_Modal_RegularByOrganization",
  mixins: [ModalComponentMixin],
  components: {
    AC_Regular: () => import("@/components/autocomplite/AC_Regular"),
    AC_RegularSocial: () => import("@/components/autocomplite/AC_RegularSocial"),
  },
  data() {
    return {
      is_legacy: this.item.is_legacy || false,
      service_id: this.item.service_id || 0,
      regulator_parameters_type_id: this.item.regulator_parameters_type_id || 0,
      social_regulator_parameters_type_id: this.item.social_regulator_parameters_type_id || 0,
      tariff_group_type_id: this.item.tariff_group_type_id || 0,
      date_start: this.item.date_start || '',
      date_end: this.item.date_end || '',
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelect',
      groups: 'getTariffGroupSelect',
    }),
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.service_id = this.item.service_id
      this.tariff_group_type_id = this.item.tariff_group_type_id
      this.regulator_parameters_type_id = this.item.regulator_parameters_type_id || 0
      this.social_regulator_parameters_type_id = this.item.social_regulator_parameters_type_id || 0
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.is_legacy = this.item.is_legacy || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення нормативу по організації, послуга: ${this.getServiceName()}, значення: ${this.value_dec}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга, вид нормативу, дата початку мають бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = {
        service_id: this.service_id,
        regulator_parameters_type_id: this.regulator_parameters_type_id,
        social_regulator_parameters_type_id: this.social_regulator_parameters_type_id,
        tariff_group_type_id: this.tariff_group_type_id,
        date_start: this.date_start,
        date_end: this.date_end,
        is_legacy: this.is_legacy || false
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_REGULAR_BY_ORGANIZATION, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload['id'] = this.itemId

        this.$store.dispatch(UPDATE_REGULAR_BY_ORGANIZATION, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    getServiceName() {
      if (this.service_id) {
        const ind = this.services.find(item => item.value === this.service_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    regularChange(payload) {
      this.regulator_parameters_type_id = payload.value
    },
    socialRegularChange(payload) {
      this.social_regulator_parameters_type_id = payload.value
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.service_id = payload.service_id
              this.regulator_parameters_type_id = payload.regulator_parameters_type_id
              this.social_regulator_parameters_type_id = payload.social_regulator_parameters_type_id
              this.tariff_group_type_id = payload.tariff_group_type_id
              this.date_start = payload.date_start
              this.date_end = payload.date_end
              this.is_legacy = payload.is_legacy || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_REGULAR_BY_ORGANIZATION, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
